$brandon-text: 'Brandon Text', Meiryo, メイリオ;
$brandon-text-light: 'Brandon Text Light', Meiryo, メイリオ;
$brandon-text-medium: 'Brandon Text Medium', Meiryo, メイリオ;
$brandon-text-bold: 'Brandon Text Bold', Meiryo, メイリオ;

$font_updates: true;

// Enable Appointment Booking Reskin 2019.
$ab_skin_v2: true;

$cr22: true;

$cr24: true;

$typography-headline--xl-letter-spacing: 0;
$typography-headline--l-letter-spacing: 0;
$typography-headline--m-letter-spacing: 0;
